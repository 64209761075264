import axios from 'axios'
import { Vehicle } from 'src/models/vehicle'
import { LogAndNotifyAxiosError } from './util'
import { Notify } from 'quasar'
import {
  LogDataDogMessage,
  LogElapsedTimeFetchVehicle,
  LogKeys,
  MetricType,
} from 'src/helpers/datadog'

export const vehiclesApiStoreInternal = () => {
  /**
   * Retrieve and return a list of vehicles from the vehicles api
   */
  async function fetchVehicles(count = 1000) {
    const VEHICLES_API_BASE_URL = String(import.meta.env.VITE_VEHICLES_API_BASE_URL)
    const url = `${VEHICLES_API_BASE_URL}/v2/vehicles`
    const params = { count }
    const startTime = new Date()

    try {
      const response = await axios.get<Vehicle[]>(url, {
        params,
      })
      if (response.data.length === 0) {
        LogElapsedTimeFetchVehicle(startTime, new Date(), response, LogKeys.VEHICLE_NO_DATA)
      } else {
        LogElapsedTimeFetchVehicle(startTime, new Date(), response, LogKeys.VEHICLE_FOUND)
      }
      //Filters vehicles that don't have a generation that begins with 2.
      const filteredVehicles = response.data.filter((vehicle) => {
        return vehicle.gen.slice(0, 2) !== '2.'
      })

      return filteredVehicles.sort((a, b) => a.vehicle_id.localeCompare(b.vehicle_id))
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (!error.response) {
          LogAndNotifyAxiosError(error, 'Error occurred calling: ' + url)
          return
        }

        if (error.response.status >= 500 && error.response.status < 600) {
          LogDataDogMessage(
            MetricType.RELIABILITY,
            'vehicles-api',
            `server error ${error.response.status}`,
            'error',
            {
              status: error.response.status,
              statusText:
                error.response.status >= 400
                  ? error.response.statusText || (error.response.data as Record<string, unknown>)
                  : error.response.statusText,
              url: error.response.config?.url ?? 'Unknown URL',
              log_key: LogKeys.VEHICLE_5xx_ERROR,
            }
          )
        }
        LogElapsedTimeFetchVehicle(startTime, new Date(), error.response, LogKeys.VEHICLE_NO_DATA)
        LogAndNotifyAxiosError(error, 'Error occurred calling: ' + url)
      } else {
        console.error(error)
        Notify.create({
          type: 'negative',
          icon: 'warning',
          message: JSON.stringify(error),
        })
      }
    }
  }

  return {
    fetchVehicles,
  }
}
